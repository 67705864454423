import * as React from 'react'
import PropTypes from 'prop-types'
import useCategoryQuery from '../../hooks/useCategoryQuery'
import useCDNImage from '../../hooks/useCDNImage'
import DotSlider from './DotSlider'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import { gridSizes } from '../../common/gatsbyImageData'
import IconLoaders from '../../icons/IconLoaders'

const LastCategoryButton = ({ brand, image, type }) => {
  const productType = type === 'watch' ? 'Mens Watch' : 'Set'
  const url = type === 'watch' ? '/fine-watches/' : '/jewelry/'
  return (
    <Link
      to={url}
      className=" grid grid-row-[auto_1fr] group border border-red-700 justify-center items-center px-3 rounded-md max-w-max hover:bg-red-800 duration-200 gap-2 py-2 bg-red-700 text-white"
    >
      <div className="aspect-square size-40 w-full">
        <IconLoaders
          type={productType}
          flashing={false}
          className=" w-full h-full flex items-center justify-center aspect-square"
        />
      </div>
      <h2 className="uppercase text-sm line-clamp-1 text-center">
        SHOP ALL {type === 'watch' ? 'WATCHES' : 'JEWELRY'}
      </h2>
    </Link>
  )
}

const CategoryButton = ({ brand, image, url, type }) => {
  const productType = type === 'watch' ? 'Mens Watch' : 'bracelet'
  const finalImage = useCDNImage(image, { productType }, gridSizes, '10em')
  return (
    <Link
      to={url}
      className="min-w-max grid grid-row-[auto_1fr] group border border-gray-400 justify-center items-center px-3 rounded-md max-w-max hover:bg-gray-100 duration-200 gap-2 py-2 "
    >
      <div className="aspect-square size-40 w-full">
        {finalImage ? (
          <GatsbyImage
            image={finalImage.gatsbyImageData}
            alt={brand}
            className={' duration-300 w-full h-full aspect-square mix-blend-multiply '}
            imgStyle={{ objectFit: 'contain' }}
          />
        ) : (
          <IconLoaders type={productType} flashing={false} />
        )}
      </div>
      <h2 className="uppercase text-sm line-clamp-1 text-center">{brand}</h2>
    </Link>
  )
}
CategoryButton.propTypes = {
  brand: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired,
  qty: PropTypes.number.isRequired,
  type: PropTypes.string,
}

const CategorySlider = ({ className, type = 'watch' }) => {
  const { watchBrands, jewelryProducts } = useCategoryQuery()

  const topWatchBrands = watchBrands
    .sort((a, b) => b.productsInStock - a.productsInStock)
    .slice(0, 11)
  const topJewelryCategories = jewelryProducts
    .sort((a, b) => b.productsInStock - a.productsInStock)
    .slice(0, 11)
  const list = type === 'watch' ? topWatchBrands : topJewelryCategories

  return (
    <DotSlider className={className}>
      {list.map(brand => (
        <CategoryButton
          key={brand.id}
          brand={brand.name}
          url={brand.urlPath}
          qty={brand.productsInStock}
          image={brand.categoryImage}
          type={type}
        />
      ))}
      <LastCategoryButton type={type} />
    </DotSlider>
  )
}
CategorySlider.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['watch', 'jewelry']),
}
export default CategorySlider

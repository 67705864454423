import * as React from 'react'
import { Link } from 'gatsby'
import CategorySlider from './sliders/CategorySlider'

const ShopWatchCategory = () => {
  return (
    <section className="py-10  w-full flex flex-col items-center">
      <h2>
        <Link className="underline-title relative " to={'/fine-watches/'}>
          Shop Pre-Owned Watches By Brand
        </Link>
      </h2>
      <CategorySlider className={'max-w-[1366px] mx-auto'} />
      <p className="py-5 leading-loose max-w-[800px] mx-auto">
        Finding the right luxury accessory can help any individual of elevated taste raise their
        style game to new heights. When looking for the best in value and incomparable elegance from
        the top designers in the world of luxury, Gray & Sons Jewelers is the top destination for
        pre-owned jewelry and watches of the highest standards. We are honored to be your source for
        a diverse selection of only the finest used jewelry and watches to add to your carefully
        cultivated personal collection of accessories. Since our team consists of Swiss-trained
        watch professionals with decades of experience, rest assured every selection has been
        carefully certified and cared for to create a beautiful accessory as close as possible to
        the condition it was in the day it was first created. We offer some of the most
        distinguished names on the market, such as Rolex, Cartier, Harry Winston, BVLGARI, and more,
        to ensure every option meets the most exacting of standards in terms of both physical appeal
        and functional elements. With our selection of used watches for sale, finding the right
        option for your needs has never been easier.
      </p>

      <div className="flex w-full justify-center">
        <Link className="red-raised-btn px-5 py-3" to="/fine-watches/">
          <h3 className="uppercase"> Shop All Pre-Owned Watches</h3>
        </Link>
      </div>
    </section>
  )
}

export default ShopWatchCategory

import * as React from 'react'
import { StaticImage, getImage, GatsbyImage } from 'gatsby-plugin-image'
import { Link, graphql, useStaticQuery } from 'gatsby'
import InputField from './form/InputField'
import FormWrapper from './form/FormWrapper'
import useFormState from './form/hooks/useFormState'
import isEmail from './form/validators/isEmail'
import { processGatsbyImageDataMock } from '../common/gatsbyImageData'

const CTABoxes = () => {
  // NOTE: we skip 2nd value (setForm) as we don't need it
  const [thankYou, setThankYou] = React.useState(false)
  const [form, , updateForm] = useFormState({ name: '', email: '' })

  const onSubmit = React.useCallback(() => {
    setThankYou(true)
  }, [setThankYou])
  const onEdit = React.useCallback(() => {
    setThankYou(false)
  }, [setThankYou])

  const { catalog } = useStaticQuery(graphql`
    query catalogCover {
      catalog: strapiCatalog {
        cover {
          gatsbyImageDataMock
        }
      }
    }
  `)
  processGatsbyImageDataMock(catalog.cover)

  return (
    <>
      <div className="group md:px-2">
        <h3 className="font-bold uppercase duration-300 group-hover:text-red-700">
          Subscribe
          <span className=" font-normal group-hover:tracking-normal group-hover:text-black">
            <br /> To Our Emails
          </span>
        </h3>
        {thankYou ? (
          <div className="w-full max-w-max items-center justify-center ">
            <div className="delay3 mx-auto max-w-min translate-y-5 scale-110">
              <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle className="checkmark__circle mx-auto" cx="26" cy="26" r="25" fill="none" />
                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
              </svg>
            </div>
            <p className="home-subscribe-animations2 rounded-md border border-gray-300 py-8 text-center px-4">
              <b className="text-xl">THANK YOU</b>
              <br />
              An email has been sent to <br /> {form.email} <br />
              <b>with a $200 discount coupon!</b>
              <br />
              <button
                onClick={onEdit}
                className="red-raised-btn home-subscribe-animations2 mt-3 px-5 py-1 uppercase"
              >
                Edit My Email
              </button>
            </p>
          </div>
        ) : (
          <FormWrapper
            form={form}
            action={onSubmit}
            name={'Homepage: Subscribe'}
            className="md:pr-2"
          >
            <InputField
              name={'name'}
              onChange={updateForm}
              form={form}
              label="Full Name"
              showErrorMessage={false}
              required={true}
              // className="--home-subscribe-animations"
            />
            <InputField
              type={'email'}
              name={'email'}
              onChange={updateForm}
              form={form}
              label="E-Mail"
              validator={isEmail}
              required={true}
              showErrorMessage={false}
              // className="--home-subscribe-animations delay1"
            />
            <button className="red-raised-btn --home-subscribe-animations delay2 my-2 px-5 py-1">
              Subscribe
            </button>
          </FormWrapper>
        )}
      </div>

      <Link to="/catalog-request/" className="group overflow-hidden">
        <h3 className="font-bold uppercase duration-300  group-hover:text-red-700">
          Register{' '}
          <span className=" font-normal group-hover:tracking-normal group-hover:text-black">
            <br />
            For Our Printed Catalog
          </span>
        </h3>
        <div className="flex aspect-video justify-center bg-[#d8eaf0] xl:aspect-square p-4">
          <GatsbyImage
            image={getImage(catalog.cover)}
            alt={'Physical Catalog'}
            className="aspect-[85/110] h-full duration-200 hover:rotate-6 hover:scale-110"
          />
          {/*<StaticImage*/}
          {/*  alt={'Physical Catalog'}*/}
          {/*  src="../images/GnS-Home-Page-REGISTER-button.png"*/}
          {/*  loading="lazy"*/}
          {/*  className="aspect-square h-full duration-200 hover:rotate-6 hover:scale-110"*/}
          {/*/>*/}
        </div>
      </Link>

      <Link to="/digital-catalog/" className="group overflow-hidden">
        <h3 className="font-bold uppercase duration-300  group-hover:text-red-700">
          Browse{' '}
          <span className=" font-normal group-hover:tracking-normal group-hover:text-black">
            <br />
            Our Digital Catalog
          </span>
        </h3>
        <div className="flex aspect-video justify-center bg-[#eee9d5] xl:aspect-square ">
          <StaticImage
            alt={'Digital Catalog'}
            src="../images/GnS-Home-Page-BROWSE-button.png"
            loading="lazy"
            placeholder="blurred"
            className="aspect-square h-full duration-200 hover:rotate-6 hover:scale-110"
          />
        </div>
      </Link>

      <Link to="/blog/" className="group overflow-hidden">
        <h3 className="font-bold uppercase duration-300 group-hover:text-red-700">
          Learn <br />
          <span className=" font-normal group-hover:tracking-normal group-hover:text-black line-clamp-1">
            From Watch and Jewelry Blogs
          </span>
        </h3>
        <div className="flex aspect-video justify-center bg-[#d8cfc6] xl:aspect-square ">
          <StaticImage
            alt={'Blog'}
            src="../images/GnS-Home-Page-LEARN-button.png"
            loading="lazy"
            placeholder="blurred"
            className="aspect-square h-full duration-200 hover:rotate-6 hover:scale-110"
          />
        </div>
      </Link>
    </>
  )
}

const HomePageCTABoxes = () => {
  return (
    <section className="grid w-full grid-cols-1 md:grid-cols-2 xl:grid-cols-4 max-w-[1366px] gap-y-5 lg:py-10">
      <CTABoxes />
    </section>
  )
}

export { CTABoxes }
export default HomePageCTABoxes
